import React, { useState, useEffect, useRef } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import useDocumentTitle from '~/hooks/useDocumentTitle';
import store from '~/store';
import { useLocalize } from '~/hooks';
import { usePopup, subscriptionType } from '../helper/store.js';
import { cn, cookie } from '~/utils';
import { useAppModel } from './EndPoint';
// import HeaderProButton from './HeaderProButton.jsx';
import { useSetOptions } from '~/hooks';
import { showPricing, setFlag } from '~/components/helper/global';
import { motion } from 'framer-motion';
import { useUpdateConversation } from '~/hooks/useUpdateConversation';
import { MdOutlineArrowRight, MdOutlineArrowDropDown } from "react-icons/md";
import ChatEndpoints from './ChatEndpoints';
import ChatModels from './ChatModels';
import ChatModelDescription from './ChatModelDescription';
import ChatExample from './ChatExample';
import { activeEndpointState } from '~/store/endpoints';
import chatbotlogo from '/assets/ChatbotAsk-logo.png';
import { isModelVisibleState } from '~/store/isModelVisible.js';
import ModelSelect from '../Input/ModelSelect/ModelSelect.jsx';

export default function Landing() {
  const [isActive, setIsActive] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const setText = useSetRecoilState(store.text);
  const conversation = useRecoilValue(store.conversation);
  const localize = useLocalize();
  const { title = localize('com_ui_new_chat') } = conversation || {};
  const chatModelsRef = useRef<HTMLDivElement | null>(null);
  const appModel = useAppModel();
  const [showModel, setShowModel] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isModelVisible, setIsModelVisible] = useRecoilState(isModelVisibleState);

  const w_msg = cookie.getValue("w_msg");
  setFlag('w_msg');

  
  useDocumentTitle(title);
  const openai_gpt4_model = import.meta.env.VITE_OPENAI_GPT4_MODEL || 'gpt-4-1106-preview';
  const openai_default_model = import.meta.env.VITE_FORCE_MODEL || 'gpt-4o-mini';

  const dalle_model = import.meta.env.VITE_DALLE_MODEL || 'dall-e-3';
  const [activeModel, setActiveModel] = useState<string>(openai_default_model);
  // const [activeEndpoint, setActiveEndpoint] = useState<string>('');
  const [activeEndpoint, setActiveEndpoint] = useRecoilState(activeEndpointState);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [isMdScreen, setisMdScreen] = useState(window.innerWidth > 768);
  const updateConversation = useUpdateConversation();
  const sState = usePopup((state) => state);
  const proState = subscriptionType((state) => state);
  const [isBackClicked, setIsBackClicked] = useState(false);


  const { setOption } = useSetOptions();
  const setValue = setOption('model');
  const { newConversation } = store.useConversation();

  const computeIsDisabled = (endpoint) => { //compute summarized isDisabled value
    const models = appModel.filter(model => model.endpoint === endpoint);
    const hasTrue = models.some(model => model.isDisabled === true);
    const hasFalse = models.some(model => model.isDisabled === false);
    return (hasTrue && !hasFalse) ? true : false;
  };

  const computeIsBeta = (endpoint) => { //compute summarized isBeta value
    const models = appModel.filter(model => model.endpoint === endpoint);
    const hasTrue = models.some(model => model.isBeta === true);
    return hasTrue;
  };

  const computeIsNew = (endpoint) => {  //compute summarized isNew value
    const models = appModel.filter(model => model.endpoint === endpoint);
    const hasTrue = models.some(model => model.isNew === true);
    return hasTrue;
  };

  const { isPro, isProMax, setIsProMax, isEnterprise, setIsEnterprise } = subscriptionType(
    (state) => state,
  );



  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { innerText } = e.target as HTMLButtonElement;
    const quote = innerText.trim();
    setText(quote);
  };



  const handleEndpointClick = (endpoint) => {
    updateConversation({ endpoint }); 
    setActiveEndpoint(endpoint);
    setShowModel(false);
    setIsModelVisible(true);
  
  
    setTimeout(() => {
      setShowModel(true);
    }, 0);
  };
  
  const handleBackClick = () => {
    setIsModelVisible(false);
    setShowModel(true);
    setIsBackClicked(true);
  };

  const handleModelClick = (model) => {

    setActiveModel(model.model);
    updateConversation(model);
    setAnimationKey(prev => prev + 1);

    if (isSmallScreen) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (model) => {
    if (!model) return;
    setActiveModel(model);
    const activeModel = model;
    if (activeModel.includes('gpt-4') || activeModel.includes('palm')) {
      if (activeModel.includes('palm')) {
        if (conversation?.endpoint !== 'google') {
          newConversation({}, { endpoint: 'google' });
        }
        setTimeout(() => {
          setValue('chat-bison');
        });
      } else if (activeModel == openai_gpt4_model || activeModel == 'gpt-4-pro-max' || activeModel == 'gpt-4o' || activeModel == 'gpt-4o-mini') {
        setValue(activeModel);
        if (conversation?.endpoint !== 'openAI') {
          const lastSelectedModel = localStorage.getItem('lastSelectedModel');
          if (lastSelectedModel) {
            const models = JSON.parse(lastSelectedModel);
            models.openAI = activeModel;
            localStorage.setItem('lastSelectedModel', JSON.stringify(models));
          }

          newConversation({}, { endpoint: 'openAI', model: activeModel });
        }
      }

    } else if (activeModel.includes('claude') && activeModel.includes('sonnet')) {
      const lastSelectedModel = localStorage.getItem('lastSelectedModel');

      if (lastSelectedModel) {
        const models = JSON.parse(lastSelectedModel);
        models.claude = activeModel;
        localStorage.setItem('lastSelectedModel', JSON.stringify(models));
      }
      newConversation({}, { endpoint: 'claude', model: activeModel });
      setTimeout(() => {
        setValue(activeModel);
      });
    } else if (activeModel === dalle_model) {
      const lastSelectedModel = localStorage.getItem('lastSelectedModel');

      // get local storage lastSelectedModel
      if (lastSelectedModel) {
        const models = JSON.parse(lastSelectedModel);
        models.openAI = activeModel;
        localStorage.setItem('lastSelectedModel', JSON.stringify(models));
      }
      newConversation({}, { endpoint: 'dallE', model: activeModel });
    } else if (activeModel === "gemini-1.5-flash") {
      newConversation({}, { endpoint: 'gemini', model: activeModel });
      setTimeout(() => {
        setValue(activeModel);
      });
    } else if (activeModel === 'meta-llama/Llama-2-70b-chat-hf') {
      newConversation({}, { endpoint: 'Opensource' });
      setTimeout(() => {
        setValue(activeModel);
      });
    } else {
      const lastSelectedModel = localStorage.getItem('lastSelectedModel');
      setValue(activeModel);
      if (conversation?.endpoint !== 'openAI') {
        if (lastSelectedModel) {
          const models = JSON.parse(lastSelectedModel);
          models.openAI = activeModel;
          localStorage.setItem('lastSelectedModel', JSON.stringify(models));
        }
        newConversation({}, { endpoint: 'openAI' });
      }
    }

    if (isSmallScreen) {
      setIsOpen(false)
    }
  };

  const user = typeof window.AIPRO_USER !== 'undefined' && window.AIPRO_USER
  useEffect(() => {
    if (user) {
      const userSubscriptionType = user.subscription_type;
      if (userSubscriptionType) {
        if (userSubscriptionType === "PRO") {
          proState.setPro(true);
        }
        if (userSubscriptionType === "PROMAX") {
          setIsProMax(true);
        }
        if (userSubscriptionType === "ENTERPRISE") {
          setIsEnterprise(true);
        }
      }
      setIsLoaded(true);
    }
  }, [user]); 
  
  useEffect(() => {
    if (!isActive) {
      handleOptionClick(openai_default_model)
    }

    setIsActive(openai_default_model);

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    const handleResizeMd = () => {
      setisMdScreen(window.innerWidth > 639);
    };

    window.addEventListener('resize', handleResizeMd);

    const handleMouseDown = (event) => {

      if (chatModelsRef.current && !chatModelsRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('touchmove', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('touchmove', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    if (activeEndpoint) {
      appModel.forEach((model) => {
        if (model && model.isDefault && model.endpoint === activeEndpoint) {
          setActiveModel(model.model);
        }
      });
    }

  }, [activeEndpoint]);

  useEffect(() => {
    setIsOpen(false);
  }, [isMdScreen]);

  useEffect(() => {
    const htmlElement = document.documentElement;
    setIsDarkMode(htmlElement.classList.contains('dark'));

    const observer = new MutationObserver(() => {
      setIsDarkMode(htmlElement.classList.contains('dark'));
    });

    observer.observe(htmlElement, { attributes: true, attributeFilter: ['class'] });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    // setActiveModel(conversation?.model ?? openai_default_model);
    // setActiveEndpoint(conversation?.endpoint ?? 'openAI');
  }, []);

  const handleDropdownClick = (endpoint) => {
    handleEndpointClick(endpoint);
    setIsOpen(false);
  };

  if (isLoaded === false) {
    return null;
  }
  const uniqueEndpoints = [...new Set(appModel.map(model => model.endpoint))];
  const filteredModels = appModel.filter(model => model.endpoint === activeEndpoint);

  return (
    <>
      <div className={`flex flex-col justify-center items-center pt-4 max-w-768:h-[200px] ${conversation?.endpoint === 'openAI' && isModelVisible ? 'h-[350px]' : 'h-[260px] max-w-414:h-[300px]'}`}>
        <div className="text-center p-2">
          {
            !isModelVisible && w_msg !== 'active' ? (
              <div className="flex flex-col justify-center items-center py-4 h-[150px] max-w-768:h-[100px]">
                <motion.div
                  className="text-[32px] text-black dark:text-white"
                  initial={isBackClicked ? { y: [-50, 0, -10, 0] } : { y: 0 }}
                  animate={{ y: 0 }}
                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                >
                  <img src={chatbotlogo} className="w-[50px] mb-4 mx-auto" alt="Chatbot Logo" />
                  How can I assist you?
                </motion.div>
              </div>
            ) : (
              w_msg !== 'active' && (
                appModel
                  .filter(model => model.model === activeModel)
                  .map((model, index) => (
                    <ChatModelDescription
                      key={index} // Animation key should be unique, not `animationKey` unless it's defined elsewhere
                      endpoint={model.endpoint}
                      title={model.title}
                      token={model.desciption.token}
                      disabled={model.desciption.disabled}
                      modelImage={model.modelImage}
                      isNew={model.isNew}
                      isBeta={model.isBeta}
                    >
                      {model.desciption.content}
                    </ChatModelDescription>
                  ))
              )
            )
          }
          <div className="text-[16px] w-full md:w-[50%] mx-auto text-center">
          </div>
          <div className="">
            {isModelVisible && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'spring', stiffness: 300, damping: 20 }} // Adjust spring for smoother bounce
                className={`modelz flex place-content-center`}>
                {showModel ? (
                  <ChatModels
                    handleBackClick={handleBackClick}
                    models={filteredModels}
                    activeModel={activeModel}
                    handleOptionClick={handleModelClick}
                    showPricing={showPricing}
                    isBeta={false}
                    isNew={false}
                  />
                ) : null}
              </motion.div>
            )}
          </div>
        </div>
        <div>
          {!isModelVisible && (
            <motion.div
              key={isModelVisible ? "modelz" : "endpointz"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={cn('endpointz models-container scrollbar-hide mx-auto flex flex-wrap h-max max-h-full w-full relative overflow-auto align-top max-w-414:pt-0 py-2 md:py-5 px-0 md:px-0', { hidden: w_msg === 'active' })}>

              <>
                <div
                  className={cn('w-full flex-row gap-2 rounded-tl-lg align-center text-sm md:flex md:flex-row text-center mx-auto justify-center max-w-1280:flex max-w-1280:flex-wrap max-w-414:flex-wrap max-w-414:p-2 max-w-817:pb-6',
                    !isOpen ? '' : 'sm:flex top-20 z-10'
                  )}
                  ref={chatModelsRef}
                >
                  {uniqueEndpoints
                    .filter(endpoint => endpoint !== "Opensource")
                    .map((endpoint, index) => {
                      const endpointModel = appModel.find(model => model.endpoint === endpoint);

                      return (
                        <ChatEndpoints
                          key={index}
                          endpoint={endpoint}
                          model={endpointModel?.model}
                          showPricing={showPricing}
                          title={endpointModel?.title || "Unknown Model"}
                          EactiveEndpoint={activeEndpoint}
                          handleEndpointClick={handleEndpointClick}
                          desciption={endpointModel?.desciption || {}}
                          modelImage={endpointModel?.modelImage}
                        />
                      );
                    })}
                </div>
              </>
            </motion.div>
          )}
        </div>
      </div>
    </>
  );
}