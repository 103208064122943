import React, { useEffect, useRef, useState } from 'react';
import { cn } from '~/utils/';
import { setCookieValue, showPricing } from '~/components/helper/global';
import {
  usePopup,
  usePricingPopup,
  subscriptionType,
  useCustomData,
  setWindowValue,
} from '~/components/helper/store';
import store from '~/store';
import { useRecoilValue, useRecoilState } from 'recoil';
import { getModelName } from '../helper/modelName.js';
import { toast } from 'react-toastify';
import { RiArrowDropDownLine, RiArrowDropRightLine, RiArrowDropLeftLine, RiArrowDropUpLine } from "react-icons/ri";
import { useAppModel } from './EndPoint';
import { activeEndpointState } from '~/store/endpoints';
import { isModelVisibleState } from '~/store/isModelVisible.js';
import ChatModels from './ChatModels';
import { useUpdateConversation } from '~/hooks/useUpdateConversation';
import { IoIosLock } from 'react-icons/io';
import { FaCircleCheck } from 'react-icons/fa6';

type ModelSelectorProps = {
  id?: string;
  title?: string;
  value: string;
  disabled?: boolean;
  setValue: (value: string) => void;
  availableValues: string[];
  showAbove?: boolean;
  showLabel?: boolean;
  containerClassName?: string;
  subContainerClassName?: string;
  className?: string;
  endpoint?: string;
};

const SCROLLBAR_SIZE = 10;

function ModelSelector({
  value,
  setValue,
  title = 'Model',
  disabled,
  availableValues,
  showAbove = false,
  showLabel = true,
  containerClassName,
  className,
  endpoint,
}: ModelSelectorProps) {
  const sState = usePopup((state) => state);
  const proState = subscriptionType((state) => state);
  const conversation = useRecoilValue(store.conversation);
  const [showPopover, setShowPopover] = useRecoilState(store.showPopover);
  const [showOpenAiModels, setShowOpenAiModels] = useRecoilState(store.showOpenAiModels);
  const pState = usePricingPopup((state) => state);
  const selectorRef = useRef<HTMLDivElement | null>(null);
  const { hasFile, imagesPreview, imageUrls } = useCustomData((state) => state);
  const [showModelSelector, setShowModelSelector] = useState(false)
  const appModel = useAppModel();
  const [activeEndpoint, setActiveEndpoint] = useRecoilState(activeEndpointState);
  const openai_default_model = import.meta.env.VITE_FORCE_MODEL || 'gpt-4o-mini';
  const [activeModel, setActiveModel] = useState<string>(openai_default_model);
  const [isModelVisible, setIsModelVisible] = useRecoilState(isModelVisibleState);
  const [showMainModelBtn, setshowMainModelBtn] = useState(false)
  const updateConversation = useUpdateConversation();
  const messagesTree = useRecoilValue(store.messagesTree);
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { newConversation } = store.useConversation();
  const [endpointHover, setEndpointHover] = useState(false);

  const openai_gpt4_model = import.meta.env.VITE_OPENAI_GPT4_MODEL || 'gpt-4-1106-preview';
  const handleOptionClick = (option: string) => {
    if ((hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0) && option !== 'gpt-4o') {
      setShowOpenAiModels(false);
      const formattedModelName = getModelName(option);
      toast.dismiss();
      toast.error(`${formattedModelName} does not support images`);
      return;
    }
    setWindowValue('mxPanelTrack', option);
    setWindowValue('mxPanelClickLocation', 'chat-page');

    if (option.includes('gpt-4') && option !== 'gpt-4o-mini' || option === 'meta-llama/Llama-3-70b-chat-hf' || option === 'gpt-4o') {
      if (
        (option == 'gpt-4o') ||
        (proState.isPro && option == 'meta-llama/Llama-3-70b-chat-hf') ||
        (proState.isProMax && option == openai_gpt4_model)
      ) {
        setValue(option);
        setActiveModel(option);
      } else {
        showPricing(() => {
          pState.setShowPricingPopup(true);
        });
      }
    } else {
      setValue(option);
      setActiveModel(option);
    }
    setShowOpenAiModels(false);
  };

  useEffect(() => {
    const forUserInterval = setInterval(() => {
      if (typeof AIPRO_USER !== 'undefined' && AIPRO_USER && AIPRO_USER.subscription_type) {
        const subscriptionType = AIPRO_USER.subscription_type;

        if (subscriptionType) {
          if (
            subscriptionType.toString().toLowerCase() !== 'basic' &&
            subscriptionType !== null &&
            subscriptionType.toString() !== ''
          ) {
            proState.setPro(true);
          }

          if (subscriptionType.toString().toLowerCase() === 'promax') {
            proState.setIsProMax(true);
          }

          if (subscriptionType.toString().toLowerCase() === 'enterprise') {
            proState.setIsEnterprise(true);
          }
        }
        clearInterval(forUserInterval);
      }
    }, 300);
  }, []);

  useEffect(() => {
      setShowModelSelector(true);
      setshowMainModelBtn(true);
      setIsHovered(true);
  }, []);


  const toggleModelDropdown = () => {
    setShowOpenAiModels((prevState) => !prevState);
  };

  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current && dropdownRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth;
      dropdownRef.current.style.width = `${buttonWidth}px`;
    }
  }, [showOpenAiModels]);

  const toggleEndpointDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const uniqueEndpoints = [...new Set(appModel.map(model => model.endpoint))];

  const handleEndpointClick = (endpoint) => {
    const endpointModels = appModel.filter(model => model.endpoint === endpoint);
    const firstAvailableModel = endpoint !== 'openAI' ? endpointModels[0].model : null;

    setActiveEndpoint((prev) => {
      const updatedEndpoint = endpoint;
      newConversation({ endpoint: updatedEndpoint });
      return updatedEndpoint;
    });

    setTimeout(() => {
      setActiveModel(firstAvailableModel);
      updateConversation({ model:firstAvailableModel, endpoint:endpoint });

    }, 100);

    setIsModelVisible(true);

  };

  if (!(messagesTree && messagesTree?.length)) {
    setShowOpenAiModels(false);
    return null;
  }

  const handleEndpointHover = (state) =>
  {
    setEndpointHover(state);
  }

  const getButtonClasses = (showMainModelBtn, endpoint) => {
    const endpointMargins = {
      gemini: "ml-[6.74rem]",
      claude: "ml-[7rem]",
      openAI: "ml-[5.83rem]",
      dallE: "ml-[4.8rem]",
      Opensource: "ml-[7.6rem]",
      Llama: "ml-[5.85rem]",
    };

    return cn(
      "main-model-btn z-50 h-10 w-10 min-w-4 flex-none items-center justify-center p-0 focus:ring-0 focus:ring-offset-0",
      showMainModelBtn ? "hidden md:flex opacity-100 pointer-events-auto" : "md:hidden opacity-0 pointer-events-none",
      endpointMargins[endpoint] || ""
    );
  };

  const getDropdownPosition = (endpoint, endpointHover) => {
    const positions = {
      gemini: endpointHover ? "!left-[109.1%]" : "left-[34%]",
      claude: endpointHover ? "!left-[105.5%]" : "left-[33%]",
      openAI: endpointHover ? "!left-[121%]" : "left-[37%]",
      dallE: endpointHover ? "!left-[137.5%]" : "left-[137.5%]",
      Opensource: "left-[99%]",
      Llama: endpointHover ? "!left-[121%]" : "left-[39%]",
    };

    return positions[endpoint] || "";
  };

  const getSelectorWidth = (endpoint) => {
    const widths = {
      gemini: "w-[11.3rem]",
      claude: "w-[12rem]",
      openAI: "w-[9.5rem]",
      Opensource: "w-[13rem]",
      Llama: "w-[9.5rem]",
    };
    return widths[endpoint] || "";
  };

  return (
    <div className="model-selector relative" ref={selectorRef}>
      <div
        className="flex flex-col relative"
      >
        <button
          type="button"
          className={getButtonClasses(showMainModelBtn, endpoint)}
          aria-label="Model selector"
        >
          <div
            className="main-endpoint-btn hidden md:flex items-center space-x-4 justify-center"
          >
            <button
              onMouseEnter={() => {
                handleEndpointHover(true);
                setShowOpenAiModels(false);
              }}
              onMouseLeave={() => handleEndpointHover(false)}
              onClick={toggleEndpointDropdown}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleEndpointHover(true);
                }
              }}
              className={`transition-all duration-300 ease-in-out pl-[0.22rem] bg-[#FAFAFA] dark:bg-[#22252A] rounded-full w-10 h-10 flex items-center overflow-hidden ${endpointHover ? "pr-8 pl-[0.22rem] ml-[112px] w-[9.5rem]" : ""}`}
            >
              <img
                src={appModel.find((model) => model.endpoint === endpoint)?.modelImage || '/assets/modelSelect.svg'}
                alt="model select"
                className={`h-auto w-8 rounded-full border border-black dark:border-white focus:border-none ${endpoint === 'gemini' ? "p-2" : "p-1"} ${endpoint === 'Llama' ? "p-1.5" : ""} ${endpoint === 'openAI' ? "dark:filter dark:invert dark:!border-black" : ""} ${endpoint === 'Opensource' ? "pr-[0.3rem] mt-0.5 dark:filter dark:invert dark:border-black" : ""} transition-opacity duration-250`}
              />

              <button className={`text-black text-sm items-center dark:text-white dark:bg-[#22252A] dark:group-hover:bg-gray-800 rounded-full py-2 transition-all duration-300 ease-in-out whitespace-nowrap pl-4 flex justify-center items-center ${endpointHover ? "opacity-100" : "opacity-0"}`}>
                AI Models
                <button className="endpoint-dropdown px-2 text-sm text-black dark:text-white flex justify-center items-center focus:pointer-events-none" tabindex="-1">
                  <RiArrowDropRightLine size={20} />
                </button>
              </button>
            </button>

            <button
              ref={buttonRef}
              type="button"
              className={cn(
                'model-selector-options hover:bg-gray-100 dark:hover:bg-gray-800 place-content-center bg-[#FAFAFA] dark:bg-[#22252A] dark:text-white px-4 py-2 rounded-full text-black items-center gap-2 transition-opacity duration-300 ease-in-out text-sm ml-2',
                !showModelSelector ? 'hidden opacity-0 pointer-events-none' : 'flex opacity-100 pointer-events-auto',
                getSelectorWidth(endpoint)
              )}
              onClick={toggleModelDropdown}
              aria-expanded="Dropdown"
              aria-label="Toggle model options"
            >
              {getModelName(conversation?.model)}
               <RiArrowDropDownLine size={20} className="ml-2" />
            </button>

            <div
              ref={dropdownRef}
              className={
                `dropdown-content absolute top-full pt-2 flex flex-col rounded-lg text-black overflow-hidden transition-all duration-300 ease-in-out px-[2px]
                ${showOpenAiModels ? 'max-h-max opacity-100 pointer-events-auto' : 'max-h-0 opacity-0 pointer-events-none'}
                ${getDropdownPosition(endpoint, endpointHover)}`}
            >
              {availableValues
                .filter(option => option !== conversation?.model)
                .map((option, i) => (
                  <button
                    key={i}
                    onClick={() => handleOptionClick(option)}
                    className={cn(
                      'model-selector-option text-left bg-[#FAFAFA] dark:bg-[#22252A] hover:bg-gray-100 hover:dark:bg-gray-800 text-sm font-medium px-5 py-2 rounded-full mb-2 dark:text-white transform transition-all duration-300 ease-out opacity-0 translate-y-0',
                      showOpenAiModels && 'opacity-100 translate-y-0',
                      proState.isPro === false &&
                      (option === openai_gpt4_model || option === 'gpt-4-pro-max' || option === 'meta-llama/Llama-3-70b-chat-hf') &&
                      'disabled',
                      (hasFile || imagesPreview?.length > 0 || imageUrls?.length > 0) &&
                      option !== 'gpt-4o' &&
                      'opacity-50 hidden'
                    )}
                    style={{
                      transitionDelay: `${i * 50}ms`
                    }}
                    role="option"
                    aria-selected={option}
                    aria-label={getModelName(option)}
                  >
                    <span className="inline-flex items-center">
                      {getModelName(option)}
                    </span>
                    {proState.isProMax == false && option === openai_gpt4_model && (
                      <IoIosLock className="ml-1 mb-0.5 mt-[1px] text-[16px] inline-block align-top text-gray-500" />
                    )}
                    {proState.isPro === false && option === 'meta-llama/Llama-3-70b-chat-hf' && (
                      <IoIosLock className="ml-1 mb-0.5 mt-[1px] text-[16px] inline-block align-top text-gray-500" />
                    )}
                    {proState.isProMax === false && option === 'gpt-4-pro-max' && (
                      <IoIosLock className="ml-1 mb-0.5 mt-[1px] text-[16px] inline-block align-top text-gray-500" />
                    )}
                  </button>
                ))}
            </div>


            <div
              onMouseEnter={() => handleEndpointHover(true)}
              onMouseLeave={() => handleEndpointHover(false)}
              className={`absolute top-full left-[-5%] pt-3 dropdown-menu w-[9.5rem] transition-opacity duration-250
              ${!isDropdownOpen && endpointHover ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
            >
              {uniqueEndpoints
              .filter(endpoint => endpoint !== "Opensource")
              .map((endpoint) => {
                const endpointModel = appModel.find(model => model.endpoint === endpoint);
                const displayName =
                  endpoint === 'openAI' ? 'GPT' :
                    endpoint === 'Opensource' ? 'LLMs' :
                      endpoint === 'dallE' ? 'DALL·E' :
                        endpoint;

                const isActive = endpoint === conversation?.endpoint;

                return (
                  <div
                    onClick={() => handleEndpointClick(endpoint)}
                    key={endpoint}
                    tabIndex="0"
                    className={cn(
                      'dropdown-active-btn flex items-center justify-start hover:bg-gray-100 hover:dark:bg-gray-800  bg-[#FAFAFA] text-black dark:text-white dark:bg-[#22252A] ml-[1.2rem] mb-2 px-2 w-[80%] py-[0.3rem] rounded-2xl capitalize ',
                      isActive && 'border dark:border-gray-700'
                    )}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleEndpointClick(endpoint);
                      }
                    }}
                  >
                    {endpointModel && (
                      <img
                        src={endpointModel?.modelImage}
                        alt={displayName}
                        className={cn(
                          'w-6 h-6 mr-2 object-contain',
                          endpoint === 'openAI' && 'dark:filter dark:invert',
                          endpoint === 'Opensource' && 'dark:filter dark:invert'
                        )}
                      />
                    )}
                    <h3 className="text-xs">{displayName}</h3>
                    {isActive && (
                      <div className="ml-">
                        <FaCircleCheck className={`ml-3 text-black dark:text-[#F0F0F0] ${endpoint === 'openAI' ? "ml-4" : ""}`} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

export default ModelSelector;
