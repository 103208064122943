import React, { useRef, useEffect } from 'react';
import { ModelSelector } from '~/components/ui';
import type { TModelSelectProps } from '~/common';

export default function Gemini({ conversation, setOption, models }: TModelSelectProps) {
  return (
    <ModelSelector
      value={conversation?.model ?? ''}
      endpoint={conversation?.endpoint ?? ''}
      setValue={setOption('model')}
      availableValues={models}
      showAbove={true}
      showLabel={false}
    />
  );
}
