export const getPrefixLocation = () => {
    const currentLocation = window.location.href;

    if (currentLocation.indexOf("staging") > -1) {
        return "staging.";
    } else if (currentLocation.indexOf("dev") > -1) {
        return "dev.";
    }

    return ""
}

export const setFlags = () => {
  const setCookie = (key, value) => {
      document.cookie = key + '=' + encodeURIComponent(value) + '; domain=.chatbotask.ai; path=/';
  };

  const paramsToTrack = [
    'ppg',
    'keyword',
    'creative',
    'emailid',
    'adid',
    'mode',
    'flow',
    'splash',
    'pmt',
    'w_msg',
    'gateway',
    'force_gateway',
    'swipe',
    'bypasscountry',
    'qW1eMlya',
    'via',
    'vprice',
    'tp_reviews',
    'members',
    'reg',
    'regRedirectWP',
    'WcvYPABR',
    'reg_google',
    'kt8typtb',
    'cta_clr_lp',
    'cta_pmt',
    'poweredby',
    'mobDisplayPlan',
    'enterprise',
    'cmpny',
    'r_flow',
    'locales',
    'f_sle',
    'verify',
    'email_login',
    'login_token',
    'emailopt',
    'p_toggle',
    'mode_test_no_pcheck',
    'force_pmt',
    'rdct',
    'price_click',
    'reg_apple',
    'vwo',
    'pp_ctaclr',
    'pp_cta',
    'chatpdf',
    'cpdflink',
    'click_id',
    'daily',
    'acwp_ux',
    'rec2',
    'ty_rdct',
    'desc_align',
    'flux_app',
    'country_code',
    'ishoneypot',
  ];

  paramsToTrack.forEach(param => {
      const paramValue = new URLSearchParams(window.location.search).get(param);
      if (paramValue !== null && paramValue !== '') {
          setCookie(param, paramValue);
      }
  });
}

export const handleRedirect = (path) => {
    window.location.href = path;
}