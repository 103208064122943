import { forwardRef } from 'react';
import { IoDiamondOutline } from "react-icons/io5";
import { RxExit } from "react-icons/rx";
import { usePricingPopup, subscriptionType, setWindowValue } from '~/components/helper/store';
import { showPricing } from '~/components/helper/global';
import { handleRedirect, getPrefixLocation } from '~/utils/main';

const NavDropdown = forwardRef(() => {
  const proState = subscriptionType((state) => state)
  const pState = usePricingPopup((state) => state);
  return (
    <div className='text-black dark:text-white !mt-5'>
      {(!proState.isPro || !proState.isProMax) && (
        <button
          onClick={() => {
            setWindowValue('mxclickLocation', 'Go-Pro-Button');
            showPricing(() => {
              pState.setShowPricingPopup(true);
            });
          }}
          className='upgrade-btn flex w-full items-center rounded-lg p-3 md:hover:bg-gray-100 dark:md:hover:bg-gray-800'>
          <IoDiamondOutline className='mr-2' />
          <div>
            <p className='text-sm font-normal text-left'>Upgrade Plan</p>
            <p className='text-xs text-black dark:text-white opacity-40 font-normal mt-1'>Access Top-Tier Models</p>
          </div>
        </button>
      )}

      <button
        onClick={() => handleRedirect("https://" + getPrefixLocation() + "chatbotask.ai/my-account")}
        className='back-dsh-btn flex items-center text-sm w-full rounded-lg p-3 md:hover:bg-gray-100 dark:md:hover:bg-gray-800'>
        <RxExit className='mr-2 transform -scale-100' /> Back to Dashboard
      </button>
    </div>
  );
});

export default NavDropdown;
