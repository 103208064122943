const openai_gpt4_model = import.meta.env.VITE_OPENAI_GPT4_MODEL || 'gpt-4-1106-preview';
const openai_gpt3_model = import.meta.env.VITE_OPENAI_GPT3_MODEL || 'gpt-3.5-turbo-0125';
const claude_model = import.meta.env.VITE_CLAUDE_MODEL || 'claude-3-5-sonnet@20240620';
const dalle_model = import.meta.env.VITE_DALLE_MODEL || 'dall-e-3';

const modelNames = {
  [openai_gpt3_model]: 'GPT-3.5',
  [openai_gpt4_model]: 'GPT-4',
  [claude_model]: 'Claude-3.5 Sonnet',
  [dalle_model]: 'DALL·E',
  'gpt-4o': 'GPT-4o',
  'gpt-4o-mini': 'GPT-4o mini',
  'gpt-4-pro-max': 'PRO MAX',
  'DALL·E': 'DALL·E 3',
  'gemini-1.5-flash': 'Gemini 1.5 Flash',
  'gemini-1.5-pro': 'Gemini 1.5 Pro',
  'meta-llama/Llama-2-70b-chat-hf': 'LLama 2 70B',
  'meta-llama/Meta-Llama-3-8B-Instruct-Turbo': 'Llama 3 8B',
  'meta-llama/Llama-3-70b-chat-hf': 'LLama 3 70B',
  'Qwen/Qwen1.5-72B-Chat': 'Qwen 1.5 Chat (72B)',
  'mistralai/Mixtral-8x22B-Instruct-v0.1': 'Mixtral-8x22B Instruct (141B)',
  'google/gemma-7b-it': 'Gemma Instruct (7B)',
  'openchat/openchat-3.5-1210': 'OpenChat 3.5 (7B)',
  'teknium/OpenHermes-2-Mistral-7B': 'OpenHermes-2-Mistral (7B)',
  'Open-Orca/Mistral-7B-OpenOrca': 'OpenOrca Mistral (7B) 8K',
  'lmsys/vicuna-13b-v1.5': 'Vicuna v1.5 (13B)',
  'Austism/chronos-hermes-13b': 'Chronos Hermes (13B)',
};

export function getModelName(model) {
  return modelNames[model] || model;
}
