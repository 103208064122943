import React, { useState, useEffect } from 'react';
import { IoIosLock } from 'react-icons/io';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { usePricingPopup, usePopup, setWindowValue } from '~/components/helper/store';
import { cookie } from '~/utils';
import global from '~/utils/global';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { activeEndpointState } from '~/store/endpoints';
import { setCookieValue } from '~/components/helper/global';
import { useSetOptions } from '~/hooks';
import { FaCircleCheck } from "react-icons/fa6";

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: (index) => ({
    y: 0,
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
      duration: 0.3,
      delay: index * 0.1
    }
  })
};

const ChatEndpoints = ({ EactiveEndpoint, endpoint, title, handleEndpointClick, isBeta = false, isNew = false, isDisabled = false, modelImage }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const userPlan = AIPRO_USER.subscription_type || {};
  const activeEndpoint = useRecoilValue(activeEndpointState);
  const setActiveEndpoint = useSetRecoilState(activeEndpointState);
  const pState = usePricingPopup((state) => state);
  const sState = usePopup((state) => state);
  const { setOption } = useSetOptions();
  // console.log("EactiveEndpoint:", EactiveEndpoint)
  const checkPlanClick = () => {
    if (isDisabled) {
      if ((endpoint == 'dallE' || endpoint == 'claude' || endpoint == 'Llama' || endpoint == 'gemini' || endpoint == 'Opensource') && (!userPlan)) {

        if (
          cookie.getValue('flow') === 'chatapp' &&
          cookie.getValue('n_flow') === 'chatapp'
        ) {
          setWindowValue('mxPanelTrack', endpoint);
          setWindowValue('mxPanelClickLocation', 'endpoint-tabs-chatpro-chatapp');
        } else {
          setWindowValue('mxPanelTrack', endpoint);
          setWindowValue('mxPanelClickLocation', 'endpoint-tabs-chatpro-modal');
        }
        global.showPricing(() => {
          pState.setShowPricingPopup(true);
        });
      } else if ((endpoint == 'gemini') && (userPlan == 'BASIC')) {
        setWindowValue('mxPanelTrack', endpoint);
        setWindowValue('mxPanelClickLocation', 'endpoint-tabs-chatpro-redirect');
        const ppg = cookie.getValue('ppg') || 72;
        window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
      } else if ((endpoint == 'dallE' || endpoint == 'claude' || endpoint == 'Opensource') && (userPlan == 'PRO')) {
        setWindowValue('mxPanelTrack', endpoint);
        setWindowValue('mxPanelClickLocation', 'endpoint-tabs-chatpro-redirect');
        const ppg = cookie.getValue('ppg') || 72;
        window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
      } else {
        if (
          cookie.getValue('flow') === 'chatapp' &&
          cookie.getValue('n_flow') === 'chatapp'
        ) {
          setWindowValue('mxPanelTrack', endpoint);
          setWindowValue('mxPanelClickLocation', 'endpoint-tabs-chatpro-chatapp');
        } else {
          setWindowValue('mxPanelTrack', endpoint);
          setWindowValue('mxPanelClickLocation', 'endpoint-tabs-chatpro-modal');
        }
        global.showPricing(() => {
          pState.setShowPricingPopup(true);
        });
      }
    } else {
      handleEndpointClick(endpoint)

    }
  };

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className='inline-flex'>
        <motion.button
          type="button"
          className={cn(
            'ios-2-cells w-full flex items-center rounded-[120px] origin-bottom-right md:hover:text-black md:hover:bg-[#F1F1F1] md:hover:dark:bg-white/10 md:hover:dark:text-white',
            isDisabled && 'locked',
            EactiveEndpoint === endpoint
              ? cn(
                'px-3 py-2 dark:text-white cursor-default border border-black dark:border-white',
                !isDisabled && 'activemobile',
                activeEndpoint === 'openAI' && 'pl-4',
                activeEndpoint === 'claude' && 'pl-3',
                activeEndpoint === 'Llama' && 'pl-3',
                activeEndpoint === 'Opensource' && 'pl-4',
              )
              : 'min-w-[80px] h-69 pl-2 px-4 py-2 border border-[#E5E5E5] dark:border-[#E5E5E580] place-content-center text-[#676767] dark:text-white cursor-pointer',
              endpoint === 'openAI' && 'pl-3',
              endpoint === 'claude' && 'pl-3',
              endpoint === 'Llama' && 'pl-4',
              endpoint === 'Opensource' && 'pl-4',
              endpoint === 'gemini' && 'pl-4',
            )}
          onClick={checkPlanClick}
          tabIndex={0}
          role="button"
          aria-label={activeEndpoint}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              checkPlanClick();
            }
          }}
          variants={itemVariants}
        >
          <img
            src={modelImage}
            className={`w-[20px] h-[20px] mr-2 ${modelImage.includes('openai') ? 'dark:filter dark:invert' : ''} ${modelImage.includes('moreLLMs') ? 'dark:filter dark:invert' : ''}`}
          />        
          {isDisabled && (
            <IoIosLock className='mr-[1px] mb-[2px] inline text-[18px] text-gray-500' />
          )}
          <div className="activetitl items-center inline-flex tabsendpoint text-nowrap text-left capitalize text-nowrap">
            {
              endpoint === 'openAI'
                ? 'GPT'
                : endpoint === 'Opensource'
                  ? 'LLMs'
                  : endpoint === 'dallE'
                    ? 'DALL·E'
                    : endpoint
            }
            {EactiveEndpoint === endpoint && <FaCircleCheck className={`ml-3 text-black dark:text-[#F0F0F0] ${endpoint === 'openAI' ? "ml-4" : ""}  `} />}
          </div>
          {isBeta && (
            <span className="py-0.25 ml-1 rounded bg-blue-200 px-1 text-[10px] text-[#4559A4]">
              Beta
            </span>
          )}
          {isNew && (
            <span className="py-0.25 ml-1 rounded bg-green-500 px-1 text-[10px] text-white">
              New
            </span>
          )}
        </motion.button>
      </div>
    </>
  )
};

export default ChatEndpoints;
