import React, { useState, useRef, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import store from '~/store';
import { useLocalize } from '~/hooks';
import { IoDiamondOutline } from "react-icons/io5";
import HeaderDropdown from '~/components/Header/HeaderDropdown';
import { usePricingPopup, subscriptionType, setWindowValue } from '~/components/helper/store';
import { showPricing } from '~/components/helper/global';
import { FaUser } from 'react-icons/fa6';
import Settings from '../Header/HeaderSettings';
import { useChatContext } from '~/hooks/ChatContext';
import { isModelVisibleState } from '~/store/isModelVisible';
import { activeEndpointState } from '~/store/endpoints';

export default function MobileNav({ setNavVisible, showThemeModal }) {
  const conversation = useRecoilValue(store.conversation);
  const { newConversation } = store.useConversation();
  const { title = 'New Chat' } = conversation || {};
  const localize = useLocalize();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const dropdownRef = useRef(null);
  const pState = usePricingPopup((state) => state);
  const proState = subscriptionType((state) => state)
  const { resetPoweredBy } = useChatContext();
  const messagesTree = useRecoilValue(store.messagesTree);
  const setIsVisible = useSetRecoilState(isModelVisibleState);
  const setActiveEndpoint = useSetRecoilState(activeEndpointState);

  const toggleDropdown = () => {
    if (isDropdownOpen) {
      setIsDropdownVisible(false);
      setTimeout(() => setIsDropdownOpen(false), 200);
    } else {
      setIsDropdownOpen(true);
      setTimeout(() => setIsDropdownVisible(true), 10);
    }
  };

  const toggleModelVisibility = (isModelVisible) => {
    const mainModelBtn = document.querySelector('.main-model-btn');
    const modelSelectorOptions = document.querySelector('.model-selector-options');

    if (mainModelBtn && modelSelectorOptions) {
      if (isModelVisible) {
        mainModelBtn.classList.remove('hidden');
        mainModelBtn.classList.add('flex');

        modelSelectorOptions.classList.remove('hidden');
        modelSelectorOptions.classList.add('inline-flex');
      } else {
        mainModelBtn.classList.remove('flex');
        mainModelBtn.classList.add('hidden');

        modelSelectorOptions.classList.remove('inline-flex');
        modelSelectorOptions.classList.add('hidden');
      }
    }
  };

  const clickHandler = () => {
    setIsVisible(false);
    toggleModelVisibility();
    setActiveEndpoint('openAI');
    newConversation();
    if (messagesTree && messagesTree.length > 0) {
      resetPoweredBy();
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
      setTimeout(() => setIsDropdownOpen(false), 200);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="mobile-nav nav-menu absolute left-0 right-0 top-0 z-20 flex items-center bg-white dark:bg-[#1A1D22] pl-1 py-2 px-2 text-gray-200 sm:pl-3 md:hidden">
      <button
        type="button"
        className="menu-btn dark:filter dark:invert -mt-0.5 inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900  dark:hover:text-white"
        onClick={() => setNavVisible((prev) => !prev)}
      >
        <span className="sr-only">{localize('com_nav_open_sidebar')}</span>
        <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.807692 0C0.36162 0 0 0.397973 0 0.888889C0 1.3798 0.36162 1.77778 0.807692 1.77778H20.1923C20.6383 1.77778 21 1.3798 21 0.888889C21 0.397973 20.6383 0 20.1923 0H0.807692ZM0 8C0 7.50908 0.36162 7.11111 0.807692 7.11111H13.2674C13.7134 7.11111 14.0751 7.50908 14.0751 8C14.0751 8.49092 13.7134 8.88889 13.2674 8.88889H0.807692C0.36162 8.88889 0 8.49092 0 8ZM0 15.1111C0 14.6203 0.36162 14.2222 0.807692 14.2222H9.11358C9.55959 14.2222 9.92127 14.6203 9.92127 15.1111C9.92127 15.602 9.55959 16 9.11358 16H0.807692C0.36162 16 0 15.602 0 15.1111Z" fill="black" />
        </svg>
      </button>
      <h1 className="nav-title flex-1 text-center text-base font-normal">
        {/* {title || localize('com_ui_new_chat')} */}
      </h1>
      <div className="flex justify-center items-center space-x-4">
        <button
          type="button"
          className="new-chat-mobile-btn"
          aria-label="new chat"
          onClick={() => clickHandler()}
        >
          <div className="dark:filter dark:invert">
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1389 1C18.8772 1 18.6181 1.05234 18.3763 1.15403C18.1345 1.25571 17.9148 1.40476 17.7298 1.59265L10.6048 8.82727C10.5433 8.88974 10.4988 8.96744 10.476 9.05268L9.68428 12.0001C9.63707 12.1759 9.68624 12.3639 9.81311 12.4927C9.93999 12.6215 10.1251 12.6714 10.2982 12.6235L13.201 11.8197C13.2849 11.7964 13.3615 11.7513 13.423 11.6888L20.548 4.45423C20.733 4.26633 20.8798 4.04327 20.98 3.79778C21.0801 3.55228 21.1317 3.28916 21.1317 3.02344C21.1317 2.75772 21.0801 2.4946 20.98 2.2491C20.8798 2.00361 20.733 1.78054 20.548 1.59265C20.3629 1.40476 20.1433 1.25571 19.9015 1.15403C19.6597 1.05234 19.4006 1 19.1389 1ZM18.759 2.09212C18.8794 2.04146 19.0085 2.01538 19.1389 2.01538C19.2693 2.01538 19.3984 2.04146 19.5188 2.09212C19.6393 2.14278 19.7487 2.21703 19.8409 2.31064C19.9331 2.40424 20.0062 2.51537 20.0561 2.63767C20.106 2.75997 20.1317 2.89106 20.1317 3.02344C20.1317 3.15582 20.106 3.2869 20.0561 3.4092C20.0062 3.53151 19.9331 3.64263 19.8409 3.73624L12.8098 10.8755L10.8793 11.4101L11.4058 9.44992L18.4369 2.31064C18.5291 2.21703 18.6385 2.14278 18.759 2.09212Z" fill="#0B1215" />
              <path d="M4.83333 1.67692C2.71624 1.67692 1 3.41957 1 5.56923V19.1077C1 21.2574 2.71624 23 4.83333 23H18.1667C20.2838 23 22 21.2574 22 19.1077V7.76923C22 7.48884 21.7761 7.26154 21.5 7.26154C21.2239 7.26154 21 7.48884 21 7.76923V19.1077C21 20.6966 19.7315 21.9846 18.1667 21.9846H4.83333C3.26853 21.9846 2 20.6966 2 19.1077V5.56923C2 3.98035 3.26852 2.69231 4.83333 2.69231H10.3889C10.665 2.69231 10.8889 2.46501 10.8889 2.18462C10.8889 1.90422 10.665 1.67692 10.3889 1.67692H4.83333Z" fill="#0B1215" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1389 1C18.8772 1 18.6181 1.05234 18.3763 1.15403C18.1345 1.25571 17.9148 1.40476 17.7298 1.59265L10.6048 8.82727C10.5433 8.88974 10.4988 8.96744 10.476 9.05268L9.68428 12.0001C9.63707 12.1759 9.68624 12.3639 9.81311 12.4927C9.93999 12.6215 10.1251 12.6714 10.2982 12.6235L13.201 11.8197C13.2849 11.7964 13.3615 11.7513 13.423 11.6888L20.548 4.45423C20.733 4.26633 20.8798 4.04327 20.98 3.79778C21.0801 3.55228 21.1317 3.28916 21.1317 3.02344C21.1317 2.75772 21.0801 2.4946 20.98 2.2491C20.8798 2.00361 20.733 1.78054 20.548 1.59265C20.3629 1.40476 20.1433 1.25571 19.9015 1.15403C19.6597 1.05234 19.4006 1 19.1389 1ZM18.759 2.09212C18.8794 2.04146 19.0085 2.01538 19.1389 2.01538C19.2693 2.01538 19.3984 2.04146 19.5188 2.09212C19.6393 2.14278 19.7487 2.21703 19.8409 2.31064C19.9331 2.40424 20.0062 2.51537 20.0561 2.63767C20.106 2.75997 20.1317 2.89106 20.1317 3.02344C20.1317 3.15582 20.106 3.2869 20.0561 3.4092C20.0062 3.53151 19.9331 3.64263 19.8409 3.73624L12.8098 10.8755L10.8793 11.4101L11.4058 9.44992L18.4369 2.31064C18.5291 2.21703 18.6385 2.14278 18.759 2.09212Z" stroke="black" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M4.83333 1.67692C2.71624 1.67692 1 3.41957 1 5.56923V19.1077C1 21.2574 2.71624 23 4.83333 23H18.1667C20.2838 23 22 21.2574 22 19.1077V7.76923C22 7.48884 21.7761 7.26154 21.5 7.26154C21.2239 7.26154 21 7.48884 21 7.76923V19.1077C21 20.6966 19.7315 21.9846 18.1667 21.9846H4.83333C3.26853 21.9846 2 20.6966 2 19.1077V5.56923C2 3.98035 3.26852 2.69231 4.83333 2.69231H10.3889C10.665 2.69231 10.8889 2.46501 10.8889 2.18462C10.8889 1.90422 10.665 1.67692 10.3889 1.67692H4.83333Z" stroke="black" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>

        </button>
        {!proState.isPro && !proState.isProMax && (
          <button
            className="md:w-[140px] text-md md:text-xl inline-block cursor-pointer rounded-full bg-[#177654] px-[0.65rem] py-[7px] text-white duration-200 ease-in"
            onClick={() => {
              setWindowValue('mxclickLocation', 'Go-Pro-Button');
              showPricing(() => {
                pState.setShowPricingPopup(true);
              });
            }}
            title="GO PRO"
            type="button"
          >
            <div className=''>
              <IoDiamondOutline size={18} className='inline mr-[0.05rem] mb-[0.15rem]' />
              <span className='hidden md:block'>GO PRO</span>
            </div>
          </button>
        )}
        <button
          className="bg-[#015288] text-white text-xl font-bold rounded-full px-2 py-2 flex items-center justify-center mx-auto"
          style={{ width: '40px', height: '40px' }}
          onClick={() => toggleDropdown()}
        >
          {AIPRO_USER.email ? <FaUser size={20} role="img" aria-label="User" /> : 'G'}
        </button>
      </div>
      {isDropdownOpen && (
        <div ref={dropdownRef}>
          <HeaderDropdown isVisible={isDropdownVisible}
            setIsDropdownVisible={setIsDropdownVisible}
            setShowSettings={setShowSettings}
            showThemeModal={showThemeModal} />
        </div>
      )}
      {showSettings && <Settings open={showSettings} onOpenChange={setShowSettings} />}
    </div>
  );
}
