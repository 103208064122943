import { useState, useRef, useEffect } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { useUpdateConversationMutation } from 'librechat-data-provider';
import RenameButton from './RenameButton';
import DeleteButton from './DeleteButton';
import store from '~/store';
import { useParams } from 'react-router-dom';
import { cn } from '~/utils';
import { useMediaQuery } from '~/hooks';

export default function Conversation({ conversation, retainView, setNavVisible }) {
  const [currentConversation, setCurrentConversation] = useRecoilState(store.conversation);
  const setSubmission = useSetRecoilState(store.submission);
  const { refreshConversations } = store.useConversations();
  const { switchToConversation } = store.useConversation();
  const { conversationId, title, messages } = conversation;
  const [renaming, setRenaming] = useState(false);
  const [titleInput, setTitleInput] = useState(title);
  const inputRef = useRef(null);
  const params = useParams();
  const messagesTree = useRecoilValue(store.messagesTree);
  const updateConvoMutation = useUpdateConversationMutation(conversationId);
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const isCurrentConversation = currentConversation?.conversationId === conversationId;

  const clickHandler = async () => {
    const msgsChecker = messages.length > 0 ? (messagesTree?.length ?? 0) > 0 : true;

    if (isCurrentConversation && msgsChecker) {
      return;
    }

    setSubmission(null);

    let title = 'ChatbotAsk |';
    let app_name = 'chatbotask.ai';
    if (import.meta.env.VITE_APP_TITLE) title = `${import.meta.env.VITE_APP_TITLE}`;
    document.title = `${title} ${app_name}`;

    if (conversation?.endpoint === 'gptPlugins') {
      const lastSelectedTools = JSON.parse(localStorage.getItem('lastSelectedTools')) || [];
      switchToConversation({ ...conversation, tools: lastSelectedTools });
    } else {
      switchToConversation(conversation);
    }

    if (isSmallScreen) {
      setNavVisible(false);
    }
  };

  const renameHandler = (e) => {
    e.preventDefault();
    setTitleInput(title);
    setRenaming(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 25);
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    setRenaming(false);
  };

  const onRename = (e) => {
    e.preventDefault();
    setRenaming(false);
    if (titleInput.trim() === title || !titleInput.trim()) {
      return;
    }
    updateConvoMutation.mutate({ conversationId, title: titleInput.trim() });
  };

  useEffect(() => {
    if (updateConvoMutation.isSuccess) {
      refreshConversations();
      if (conversationId === currentConversation?.conversationId) {
        setCurrentConversation((prevState) => ({
          ...prevState,
          title: titleInput,
        }));
      }
    }
  }, [updateConvoMutation.isSuccess]);

  useEffect(() => {
    if (params.conversationId === conversationId) {
      clickHandler();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onRename(e);
    }
  };

  const onBlur = () => {
    setTimeout(() => {
      setRenaming(false);
    }, 500);
  };

  return (
    <div
      data-testid="convo-item"
      onClick={() => clickHandler()}
      className={cn(
        'conversation-link group relative pl-2 flex cursor-pointer items-center gap-3 break-all rounded-lg py-3 px-2 md:hover:bg-gray-100 dark:md:hover:bg-gray-800 focus-visible:mt-0.5 focus-visible:mx-1',
        {
          'active animate-flash bg-gray-100 dark:bg-gray-800': isCurrentConversation,
        },
      )}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          clickHandler();
        }
      }}
      aria-label="conversation link"
    >
      <div
        className='relative max-h-5 w-32 flex-1 overflow-hidden text-ellipsis whitespace-nowrap break-all'
        aria-label="conversation title"
      >
        {renaming === true ? (
          <input
            ref={inputRef}
            type="text"
            className="m-0 mr-0 w-full border border-blue-500 bg-transparent p-0 text-sm leading-tight outline-none"
            value={titleInput}
            onChange={(e) => setTitleInput(e.target.value)}
            onBlur={onBlur}
            onKeyDown={handleKeyDown}
          />
        ) : (
          title
        )}
      </div>
      <div
        className={cn(
          'conversation-action-button flex',
          {
            'hidden md:group-hover:flex': !isSmallScreen,
            'md:flex': !isSmallScreen && isCurrentConversation,
            'hidden': isSmallScreen && !isCurrentConversation,
          },
        )}
      >
        <RenameButton
          isCurrentConversation={isCurrentConversation}
          conversationId={conversationId}
          renaming={renaming}
          renameHandler={renameHandler}
          onRename={onRename}
        />
        <DeleteButton
          isCurrentConversation={isCurrentConversation}
          conversationId={conversationId}
          renaming={renaming}
          cancelHandler={cancelHandler}
          retainView={retainView}
        />
      </div>
    </div>
  );
}