import { ModelSelector } from '~/components/ui';
import type { TModelSelectProps } from '~/common';

export default function ChatGPT({ conversation, setOption, models }: TModelSelectProps) {
  if (!conversation) {
    return null;
  }
  const { conversationId, model } = conversation;
  if (conversationId !== 'new') {
    return null;
  }

  return (
    <ModelSelector
      value={model ?? ''}
      setValue={setOption('model')}
      availableValues={models}
      showAbove={true}
      showLabel={false}
    />
  );
}
