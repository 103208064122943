/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Outlet } from 'react-router-dom';
// import MessageHandler from '../components/MessageHandler';
import {
  useGetEndpointsQuery,
  useGetPresetsQuery,
  useGetSearchEnabledQuery,
} from 'librechat-data-provider';

import { Nav, MobileNav } from '~/components/Nav';
import { useAuthContext, useServerStream } from '~/hooks';
import store from '~/store';

import ThemesModal from '~/components/Themes/ThemesModal';
import Popup from '../components/ui/Popup';
import { cookie, cn } from '~/utils';
import PricingPopup from '../components/ui/PricingPopup';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '~/components/Header/Header';

export default function Root() {
  const [navVisible, setNavVisible] = useState(() => {
    const savedNavVisible = localStorage.getItem('navVisible');
    return savedNavVisible !== null ? JSON.parse(savedNavVisible) : window.innerWidth > 767;
  });

  const [isOpen, setIsOpen] = useState(false);
  const [hasOpened, sethasOpened] = useState(false);

  const submission = useRecoilValue(store.submission);
  useServerStream(submission ?? null);

  const setIsSearchEnabled = useSetRecoilState(store.isSearchEnabled);
  const setEndpointsConfig = useSetRecoilState(store.endpointsConfig);
  const setPresets = useSetRecoilState(store.presets);

  const user_secret_id = import.meta.env.USER_SECRET_ID
    ? import.meta.env.USER_SECRET_ID
    : 'chatpro_KJLF4XgSL8wjlGm';
  const user = cookie.getValue(user_secret_id);

  const searchEnabledQuery = useGetSearchEnabledQuery();
  const endpointsQuery = useGetEndpointsQuery();
  const presetsQuery = useGetPresetsQuery({ enabled: !!user });
  const [isIos141, setIsIos141] = useState(false);

  useEffect(() => {
    if (/iPhone|iPad/i.test(navigator.userAgent)) {
      var match = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
      if (match !== null) {
        var version = parseInt(match[1], 10);
        if (version <= 14 && (version < 14 || parseInt(match[2], 10) <= 1)) {
          setIsIos141(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (endpointsQuery.data) {
      setEndpointsConfig(endpointsQuery.data);
    } else if (endpointsQuery.isError) {
      console.error('Failed to get endpoints', endpointsQuery.error);
    }
  }, [endpointsQuery.data, endpointsQuery.isError]);

  useEffect(() => {
    if (presetsQuery.data) {
      setPresets(presetsQuery.data);
    } else if (presetsQuery.isError) {
      console.error('Failed to get presets', presetsQuery.error);
    }
  }, [presetsQuery.data, presetsQuery.isError]);

  useEffect(() => {
    if (searchEnabledQuery.data) {
      setIsSearchEnabled(searchEnabledQuery.data);
    } else if (searchEnabledQuery.isError) {
      console.error('Failed to get search enabled', searchEnabledQuery.error);
    }
  }, [searchEnabledQuery.data, searchEnabledQuery.isError]);

  // if (!isAuthenticated) {
  //   return null;
  // }

  return (
    <>
      <div className={cn('flex h-full max-h-screen', isIos141 ? 'ios-14-1' : '')}>
        <Header
          showThemeModal={() => {
            setIsOpen(true);
            sethasOpened(true);
          }}
          navVisible={navVisible}
          />
        <Popup></Popup>
        <PricingPopup></PricingPopup>
        <Nav
          navVisible={navVisible}
          setNavVisible={setNavVisible}
          showThemeModal={() => {
            setIsOpen(true);
            sethasOpened(true);
          }}
        />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          transition={Zoom}
          stacked
        />
        <div className="flex h-full w-full min-w-0 flex-1 flex-col bg-white dark:bg-[#1A1D22]">
        
      <div className="main-contents  scrollbar-hide transition-width relative flex h-full w-full flex-1 flex-col items-stretch overflow-hidden dark:bg-[#1a1d22] md:pt-0">
       {/* <div className="main-contents scrollbar-hide transition-width md:relative flex h-full w-full flex-1 flex-col items-stretch overflow-hidden dark:bg-[#242425] md:pt-0 overflow-auto"> */}
            <MobileNav setNavVisible={setNavVisible} showThemeModal={() => {
            setIsOpen(true);
            sethasOpened(true);
          }} />
            <Outlet />
          </div>
        </div>
      </div>
      {hasOpened && (
        <ThemesModal
          isOpen={isOpen}
          close={() => {
            setIsOpen(false);
          }}
        />
      )}

      {/* <MessageHandler /> */}
    </>
  );
}
