import React, { useState, useEffect, useRef } from 'react';
import { IoIosLock } from 'react-icons/io';
import { MdOutlineArrowDropDown, MdOutlineArrowRight } from 'react-icons/md';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { usePopup, usePricingPopup, setWindowValue } from '~/components/helper/store';
import { cookie } from '~/utils';
import global from '~/utils/global';
import { setCookieValue } from '~/components/helper/global';
import { useSetOptions } from '~/hooks';
import { IoIosArrowRoundBack } from "react-icons/io";

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: (index) => ({
    y: 0,
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
      duration: 0.3,
      delay: index * 0.1,
    },
  }),
};
import { PiSparkle } from "react-icons/pi";

const ChatModels = ({ models, activeModel, handleOptionClick, handleBackClick }) => {
  const { setOption } = useSetOptions();
  const pState = usePricingPopup((state) => state);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [truncated, setTruncated] = useState({});
  const userPlan = AIPRO_USER.subscription_type || {};
  const sState = usePopup((state) => state);
  const w_msg = cookie.getValue('w_msg');

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchmove', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchmove', handleClickOutside);
    };
  }, []);

  const checkTruncation = () => {
    models.forEach((model, index) => {
      const element = document.getElementById(`model-title-${index}`);
      if (element && element.scrollWidth > element.clientWidth) {
        setTruncated((prevState) => ({ ...prevState, [index]: true }));
      } else {
        setTruncated((prevState) => ({ ...prevState, [index]: false }));
      }
    });
  };

  useEffect(() => {
    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [models]);

  useEffect(() => {
    if (activeModel) {
      // console.log("activeModel:", activeModel)
      const setModel = setOption('model');
      setModel(activeModel);
    }
  }, []);

  const handleModelClick = (model) => {
    const models = model.title || {};
    // console.log("models", models)

    if (model.isDisabled) {
      // GUEST - REGISTERED UNPAID
      if ((models == 'claude' || models == 'Llama' || models == 'gemini' || models == 'Opensource') && (!userPlan)) {
        if (
          cookie.getValue('flow') === 'chatapp' &&
          cookie.getValue('n_flow') === 'chatapp' &&
          !cookie.getValue('user_plan')
        ) {
          setWindowValue('mxPanelTrack', models);
          setWindowValue('mxPanelClickLocation', 'modal-btn-chatpro-chatapp');
        } else {
          setWindowValue('mxPanelTrack', models);
          setWindowValue('mxPanelClickLocation', 'modal-btn-chatpro-modal');
        }
        global.showPricing(() => {
          pState.setShowPricingPopup(true);
        });
        // PRO
      } else if (( models == 'LLaMA-2 Chat (70B)') || models == 'DALL·E' && (userPlan == 'PRO')) {
        setWindowValue('mxPanelTrack', models);
        setWindowValue('mxPanelClickLocation', 'modal-tabs-chatpro-redirect');
        const ppg = 72;
        window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
        // BASIC with specific models
      } else if ((models == 'GPT-4' || models == 'LLama 2 70B') && (userPlan == 'BASIC')) {
        setWindowValue('mxPanelTrack', models);
        setWindowValue('mxPanelClickLocation', 'modal-tabs-chatpro-redirect');
        const ppg = 72;
        window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
        // Default
      } else if ((models == 'LLama 3 70B') && (userPlan == 'BASIC')) {
        setWindowValue('mxPanelTrack', models);
        setWindowValue('mxPanelClickLocation', 'modal-tabs-chatpro-redirect');
        const ppg = 14;
        window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
      } else if ((models == 'Llama 3 8B') && (userPlan == 'BASIC')) {
        setWindowValue('mxPanelTrack', models);
        setWindowValue('mxPanelClickLocation', 'modal-btn-chatpro-modal')
        handleOptionClick(model);
      }
       else {
        if (
          cookie.getValue('flow') === 'chatapp' &&
          cookie.getValue('n_flow') === 'chatapp' &&
          !cookie.getValue('user_plan')
        ) {
          setWindowValue('mxPanelTrack', models);
          setWindowValue('mxPanelClickLocation', 'modal-btn-chatpro-chatapp')
        } else {
          setWindowValue('mxPanelTrack', models);
          setWindowValue('mxPanelClickLocation', 'modal-btn-chatpro-modal')
        }
        global.showPricing(() => {
          pState.setShowPricingPopup(true);
        });
      }
    } else {

      setWindowValue('mxPanelTrack', models);
      setWindowValue('mxPanelClickLocation', 'modal-btn-chatpro-modal')
      handleOptionClick(model);
    }
    setIsDropdownOpen(false);
  };

  if(w_msg === 'active'){ return null; }

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`flex flex-wrap gap-2 my-6 place-content-center ${activeModel && activeModel.toLowerCase().includes('gpt') ? 'justify-center' : ''
          } ${activeModel && activeModel.toLowerCase().includes('google') ? 'pt-3' : ''
          }`}
      >
        <button
          className="text-[#676767] dark:text-white inline-flex items-center mr-4"
          onClick={handleBackClick}
        >
          <IoIosArrowRoundBack className="mr-2" /> back
        </button>
        {models.map((model, index) => (
          <motion.button
            key={index}
            type="button"
            title={model.title}
            className={cn(
              'rounded-[25px] text-sm font-medium py-1.5 items-center inline-flex place-content-center w-max self-center',
              activeModel === model.model && !model.isDisabled
                ? 'bg-[#1C1C1C] dark:bg-white border-[#E5E5E5] border dark:text-[#676767] text-white shadow-md px-3 mr-2'
                : 'locked border-[#E5E5E5] border text-[#676767] dark:text-gray-200 px-3 mr-2 md:hover:bg-[#F1F1F1] md:hover:text-black md:dark:hover:text-white md:dark:hover:bg-white/10'
            )}
            onClick={() => handleModelClick(model)}
            tabIndex={0}
            aria-label={model.title}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleModelClick(model);
              }
            }}
            variants={itemVariants}
            custom={index}
          >
            <div className="overflow-hidden inline-flex">
              <motion.div
                id={`model-title-${index}`}
                className={cn(
                  'inline-block',
                  model.isNew
                    ? 'max-w-[150px] max-w-1024:max-w-[160px] min-w-1281:max-w-[150px]'
                    : ''
                )}
                title={model.title}
                whileHover={truncated[index] ? { x: [0, -70] } : {}}
                initial={{ x: 0 }}
                animate={{ x: 0 }}
                transition={{ duration: 1.2, ease: 'linear' }}
              >
                {model.title}
              </motion.div>
            </div>
            {model.isDisabled && (
              <IoIosLock className="ml-1 mb-0.5 mt-[1px] text-[16px] inline-block align-top text-gray-500" />
            )}
            {/* {model.isBeta && (
              <span className="align-top ml-[2px] rounded-sm bg-blue-200 px-[2px] text-[10px] py-[2px] text-[#4559A4] transform -skew-x-12">
                Beta
              </span>
            )} */}
            {model.isNew && (
              <span className="inline-flex ml-[2px] self-center mb-1">
                <PiSparkle />
              </span>
            )}
          </motion.button>
        ))}
      </motion.div>

    </>
  );
};

export default ChatModels;
