import React from 'react';
import { motion } from 'framer-motion';
import { usePricingPopup } from '../helper/store.js';
import { HiSparkles } from "react-icons/hi2";
import global from '~/utils/global';

const descriptionVariants = {
  hidden: { opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { duration: 0.3 } },
};

const ChatModelDescription = ({ title, token, children, disabled, modelImage, endpoint, isNew, isBeta }) => {
  const pState = usePricingPopup((state) => state);
  return (
    <>
      <motion.div
        initial="hidden"
        animate={{ opacity: 1, y: [20, 0, -10, 0] }}
        exit={{ opacity: 0, y: [0, -10] }}
        transition={{ type: 'spring', stiffness: 300, damping: 20 }}
        variants={descriptionVariants}
        className={`flex flex-col justify-center items-center py-2 max-w-768:h-[110px] md:pb-24 ${endpoint === 'openAI' ? 'h-[250px]' : ' h-[200px]'}`}>
        <img
          src={modelImage}
          className={`flex justify-self-center ${endpoint === 'openAI' ? 'filter dark:invert' : ''} ${endpoint === 'Opensource' ? 'filter dark:invert' : ''}`}
        />
        <span role="contentinfo" aria-label={title} className='text-black dark:text-white font-bold text-[20px] py-4'>
          {title}
        </span>
        {endpoint === 'openAI' && (
        <img src={'/assets/powered-by-openai-badge-outlined-on-light.svg'} className='h-8 mb-5 dark:filter dark:invert'/>
        )}
        {/* {token && <p aria-label="token" className='text-[12px] text-gray-300 pt pb-4 inline-block md:hidden'>Context token: {token}</p>} */}
        <div className={`text-black dark:text-gray-300 text-sm max-w-[700px] pt-5 md:pt-0 ${endpoint === 'openAI' ? 'md:pb-10 max-w-430:pb-12 pb-5' : 'md:pb-0 max-w-430:pb-5 pb-12'}`}>
          <strong>{title}</strong>
          {isNew && <span className="text-green-500 ml-1 font-semibold">(New) </span>}
          {isBeta && <span className="text-[#4385FF] ml-1 font-semibold">(Beta) </span>}
          {children}
        </div>
      </motion.div>
      {/* <motion.div
        initial="hidden"
        animate={{ opacity: 1, y: [20, 0, -10, 0] }} // Bounce effect
        exit={{ opacity: 0, y: [0, -10] }}  // Exit with a little bounce
        transition={{ type: 'spring', stiffness: 300, damping: 20 }} // Adjust spring for smoother bounce
        variants={descriptionVariants}
        className=""
      >
        <img src={modelImage} className='flex justify-self-center' />
        <div className="text-black dark:text-white font-bold text-[20px]">
          <span role="contentinfo" aria-label={title} className='hidden md:block'>
            {title}
          </span>
          {token && <p aria-label="token" className='text-[12px] text-gray-300 pt pb-4 inline-block md:hidden'>Context token: {token}</p>}
          {disabled && (
            <button
              className="rounded-2xl bg-yellow-500 mb-[20px] px-2 py-1 text-xs font-semibold tracking-wider text-white"
              onClick={() => {
                global.showPricing(()=>{
                  pState.setShowPricingPopup(true);
                });
              }}
              title={disabled}
              type="button"
            >
              {disabled} <HiSparkles className='inline'/>
            </button>
          )}
        </div>
        {token && <p aria-label="token" className='text-[12px] text-gray-600 dark:text-gray-300 pt pb-4 hidden'>Context token: {token}</p>}
        <p className="text-black dark:text-gray-300 min-h-[80px] max-w-[700px] mx-auto px-10 py-3 text-sm" aria-label="">
          {children}
        </p>
      </motion.div> */}
    </>
  );
};

export default ChatModelDescription;
