import { useEffect, useRef } from 'react';
import CrossIcon from '../svg/CrossIcon';
import { useRecoilValue } from 'recoil';
import { useDeleteConversationMutation } from 'librechat-data-provider';
import { FiTrash } from 'react-icons/fi';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import store from '~/store';

export default function DeleteButton({ conversationId, renaming, cancelHandler, retainView, isCurrentConversation }) {
  const buttonRef = useRef(null);
  const tooltipRef = useRef(null);
  const currentConversation = useRecoilValue(store.conversation) || {};
  const { newConversation } = store.useConversation();
  const { refreshConversations } = store.useConversations();

  const deleteConvoMutation = useDeleteConversationMutation(conversationId);

  useEffect(() => {
    if (deleteConvoMutation.isSuccess) {
      if (currentConversation?.conversationId == conversationId) {
        newConversation();
      }
      refreshConversations();
      retainView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteConvoMutation.isSuccess]);

  useEffect(() => {
    if (buttonRef.current) {
      if (!tooltipRef.current) {
        tooltipRef.current = tippy(buttonRef.current, {
          content: renaming ? 'Cancel' : 'Delete',
          placement: 'top-end',
          delay: [500, 0],
          arrow: true,
          theme: 'tooltip-black',
          trigger: 'mouseenter focus',
          touch: false,
        });
      } else {
        tooltipRef.current.setContent(renaming ? 'Cancel' : 'Delete');
      }
    }
  }, [renaming]);

  const clickHandler = () => {
    deleteConvoMutation.mutate({ conversationId, source: 'button' });
  };

  const handler = renaming ? cancelHandler : clickHandler;

  return (
    <div className="group/delete relative">
      <button ref={buttonRef} className={`delete-btn px-1 ${!isCurrentConversation ? 'opacity-50 hover:opacity-100' : ''}`} onClick={handler}>
        {renaming ? <CrossIcon /> : <FiTrash />}
      </button>
    </div>
  );
}
