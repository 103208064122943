/* eslint-disable react-hooks/exhaustive-deps */
import { useGetConversationByIdQuery } from 'librechat-data-provider';
import { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import copy from 'copy-to-clipboard';
import { SubRow, Plugin, MessageContent } from './Content';
// eslint-disable-next-line import/no-cycle
import MultiMessage from './MultiMessage';
import HoverButtons from './HoverButtons';
import SiblingSwitch from './SiblingSwitch';
import { getIcon } from '~/components/Endpoints';
import { useMessageHandler, useSetOptions } from '~/hooks';
import type { TMessageProps } from '~/common';
import store from '~/store';
import { useCustomData, subscriptionType } from '~/components/helper/store';
import { cn, cookie } from '~/utils';
export default function Message({
  conversation,
  message,
  scrollToBottom,
  currentEditId,
  setCurrentEditId,
  siblingIdx,
  siblingCount,
  setSiblingIdx,
}: TMessageProps) {
  const setLatestMessage = useSetRecoilState(store.latestMessage);
  const [hasImage, setHasImage] = useState(false);
  const [abortScroll, setAbort] = useState(false);
  const { isSubmitting, ask, regenerate, handleContinue } = useMessageHandler();
  const { switchToConversation } = store.useConversation();
  const {
    text,
    children,
    messageId = null,
    searchResult,
    isCreatedByUser,
    error,
    unfinished,
    image_urls = [],
  } = message ?? {};
  const { setOption } = useSetOptions();

  const setModel = setOption('model');
  
  const proState = subscriptionType((state) => state);

  const { setHasFile } = useCustomData((state) => state);
  useEffect(() => {
    if (image_urls && image_urls.length > 0) {
      setHasFile(true);
      setModel('gpt-4o');
    }
  }, [image_urls]);

  const isLast = !children?.length;
  const edit = messageId == currentEditId;
  const getConversationQuery = useGetConversationByIdQuery(message?.conversationId ?? '', {
    enabled: false,
  });
  const blinker = message?.submitting && isSubmitting;
  // debugging
  // useEffect(() => {
  //   console.log('isSubmitting:', isSubmitting);
  //   console.log('unfinished:', unfinished);
  // }, [isSubmitting, unfinished]);

  useEffect(() => {
    if (blinker && scrollToBottom && !abortScroll) {
      scrollToBottom();
    }
  }, [isSubmitting, blinker, text, scrollToBottom]);

  useEffect(() => {
    if (!message) {
      return;
    } else if (isLast) {
      setLatestMessage({ ...message });
    }
  }, [isLast, message]);

  const enterEdit = (cancel?: boolean) =>
    setCurrentEditId && setCurrentEditId(cancel ? -1 : messageId);

  const handleWheel = () => {
    if (blinker) {
      setAbort(true);
    } else {
      setAbort(false);
    }
  };

  const props = {
    className:
      'w-full dark:border-gray-900/50 text-gray-800 bg-white dark:text-[#f5f5f5] group dark:bg-[#1A1D22]',
    titleclass: '',
  };

  const icon = getIcon({
    ...conversation,
    ...message,
    model: message?.model ?? conversation?.model,
  });

  if (!isCreatedByUser) {
    props.className =
      'w-full bg-white dark:border-gray-900/50 text-gray-800 dark:text-[#f5f5f5] group bg-gray-100 dark:bg-[#1A1D22]';
  }

  if (message?.bg && searchResult) {
    props.className = message?.bg?.split('hover')[0];
    props.titleclass = message?.bg?.split(props.className)[1] + ' cursor-pointer';
  }

  const upgradeUrl = import.meta.env.VITE_UPGRADE_URL || 'https://chatbotask.ai/upgrade';

  const regenerateMessage = async () => {
    if (!isSubmitting && !isCreatedByUser) {
      const parentMessageId = message?.parentMessageId;
      if (
        (message?.sender == 'dallE') &&
        (!proState.isProMax)
      ) {
        const ppg = cookie.getValue('ppg') || 59;
  
        window.open(`${upgradeUrl}/?ppg=${ppg}`, '_blank');
      } else {
        regenerate({ parentMessageId });
      }
    }
  };

  const copyToClipboard = (setIsCopied: React.Dispatch<React.SetStateAction<boolean>>) => {
    let text_to_copy = text?.replaceAll(':::plugin:::\n', '');
    text_to_copy = text_to_copy?.replaceAll(/!\[.*\]\(.*\)\n\n/g, '');
    setIsCopied(true);
    copy(text_to_copy ?? '');

    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const submitLanguage = async (language: string) => {
    const parentMessageId = message?.parentMessageId;
    const translateWithRegenerate = regenerate;
    if (!isSubmitting && !isCreatedByUser && parentMessageId) {
      translateWithRegenerate({
        parentMessageId,
        text_to_translate: `Translate the following text into ${language}:\n ${text}`,
      });
    }
  };

  useEffect(() => {
    if (text) {
      const re = /!\[.*\]\(.*\)\n\n/g;
      if (text.match(re)) {
        setHasImage(true);
      } else {
        setHasImage(false);
      }

      const marker = '=== LIMITED ACCESS ===';
      if (text.includes(marker)) {
        if (typeof scrollToBottom === 'function') {
          scrollToBottom();
        }
      }
    }
  }, [text]);

  if (!message) {
    return null;
  }

  const copyImage = (setIsCopiedImage: React.Dispatch<React.SetStateAction<boolean>>) => {
    const data_id = `[data-id="${message.messageId}"]`;
    const image = document.querySelector(data_id + ' img') as HTMLImageElement;
    if (image) {
      setIsCopiedImage(true);
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;

      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob: Blob | null) => {
          if (blob) {
            navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]);
          }
        }, 'image/png');
      }
    }
    setTimeout(() => {
      setIsCopiedImage(false);
    }, 500);
  };
  const downloadImage = () => {
    const data_id = `[data-id="${message.messageId}"]`;
    const image = document.querySelector(data_id + ' img') as HTMLImageElement;
    if (image) {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;

      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(image, 0, 0, image.width, image.height);
        const dataUrl = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.href = dataUrl;
        downloadLink.download = 'image.png';
        downloadLink.click();
      }
    }
  };

  const clickSearchResult = async () => {
    if (!searchResult) {
      return;
    }
    if (!message) {
      return;
    }
    getConversationQuery.refetch({ queryKey: [message?.conversationId] }).then((response) => {
      console.log('getConversationQuery response.data:', response.data);
      if (response.data) {
        switchToConversation(response.data);
      }
    });
  };

  useEffect(() => {
    if (message.model === 'dall-e-3') {
        setTimeout(() => {
          scrollToBottom();
        }, 2000);
    }
  }, [unfinished]);

  return (
    <>
      <div {...props} onWheel={handleWheel}>

        <div 
        style={isCreatedByUser ? { direction: 'rtl' } : {}}
        className="message-container m-auto flex gap-4 p-2.5 text-base md:max-w-2xl md:gap-6 lg:max-w-2xl lg:px-0 xl:max-w-3xl">
          <div className="sender-avatar relative flex h-[30px] w-[30px] flex-col items-end text-right text-xs md:text-sm">
            {typeof icon === 'string' && /[^\\x00-\\x7F]+/.test(icon as string) ? (
              <span className=" direction-rtl w-40 overflow-x-scroll">{icon}</span>
            ) : (
              icon
            )}
            
          </div>
          <div className="message-content-container dark:text-[#f5f5f5] relative flex flex-col gap-1 md:gap-3 max-w-[92.125%]">
            {searchResult && (
              <SubRow containerClassName={cn(props.titleclass, 'rounded')}
                innerContainerClassName="switch-result pl-2 pb-2"
                onClick={clickSearchResult}
              >
                <strong>{`${message?.title} | ${message?.sender}`}</strong>
              </SubRow>
            )}
            <div className={`flex flex-grow gap-3 posltr max-w-[100%] ${isCreatedByUser ? 'bg-[#F5F5F5] dark:bg-[#22252A] p-3 rounded-xl' : ''}`}>
              {/* Legacy Plugins */}
              {message?.plugin && <Plugin plugin={message?.plugin} />}
              <MessageContent
                ask={ask}
                edit={edit}
                isLast={isLast}
                text={text ?? ''}
                message={message}
                enterEdit={enterEdit}
                error={error ?? false}
                isSubmitting={isSubmitting}
                unfinished={unfinished ?? false}
                isCreatedByUser={isCreatedByUser ?? true}
                siblingIdx={siblingIdx ?? 0}
                setSiblingIdx={
                  setSiblingIdx ??
                  (() => {
                    return;
                  })
                }
              />
              {image_urls && image_urls.length > 0 && (
                <div className={cn('flex flex-row flex-wrap gap-2 pl-2  pt-2 md:pl-4 md:pt-4')}>
                  {image_urls.map((image: { image_url: { url: string } }, index: number) => {
                    return (
                      <div key={index} className="group relative rounded-xl">
                        <img
                          key={index}
                          className={cn('w-[300px] max-w-full rounded-[6px] shadow-md')}
                          src={image?.image_url?.url}
                          alt={`Upload Preview ${index}`}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <HoverButtons
              isEditing={edit}
              isSubmitting={isSubmitting}
              message={message}
              conversation={conversation ?? null}
              enterEdit={enterEdit}
              regenerate={() => regenerateMessage()}
              handleContinue={handleContinue}
              copyToClipboard={copyToClipboard}
              hasImage={hasImage}
              copyImage={copyImage}
              downloadImage={downloadImage}
              text={text ?? ''}
              submitLanguage={submitLanguage}
            />
            {!isSubmitting && message && siblingCount && siblingCount > 1 && (
              <div className="sibling-switch absolute flex items-center justify-right gap-1 text-xs relative pt-2">
              <SiblingSwitch
                siblingIdx={siblingIdx}
                siblingCount={siblingCount}
                setSiblingIdx={setSiblingIdx}
              />
            </div>
            )}
          </div>
        </div>
      </div>
      <MultiMessage
        messageId={messageId}
        conversation={conversation}
        messagesTree={children ?? []}
        scrollToBottom={scrollToBottom}
        currentEditId={currentEditId}
        setCurrentEditId={setCurrentEditId}
      />
    </>
  );
}
