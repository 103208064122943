import React, { useState, useEffect } from 'react';
import store from '~/store';
import { useChatContext } from '~/hooks/ChatContext';
import { useRecoilValue, useSetRecoilState  } from 'recoil';
import { IoMdAddCircleOutline } from "react-icons/io";
import { isModelVisibleState } from '~/store/isModelVisible';
import { activeEndpointState } from '~/store/endpoints';

const NewChat = ({ onAfterClick, setNavVisible }) => {
  const { newConversation } = store.useConversation();
  const { resetPoweredBy } = useChatContext();
  const messagesTree = useRecoilValue(store.messagesTree);
  const [isMobile, setIsMobile] = useState(false);
  const setIsVisible = useSetRecoilState(isModelVisibleState);
  const setActiveEndpoint = useSetRecoilState(activeEndpointState);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const toggleModelVisibility = (isModelVisible) => {
    const mainModelBtn = document.querySelector('.main-model-btn');
    const modelSelectorOptions = document.querySelector('.model-selector-options');

    if (mainModelBtn && modelSelectorOptions) {
      if (isModelVisible) {
        mainModelBtn.classList.remove('hidden');
        mainModelBtn.classList.add('flex');

        modelSelectorOptions.classList.remove('hidden');
        modelSelectorOptions.classList.add('inline-flex');
      } else {
        mainModelBtn.classList.remove('flex');
        mainModelBtn.classList.add('hidden');

        modelSelectorOptions.classList.remove('inline-flex');
        modelSelectorOptions.classList.add('hidden');
      }
    }
  };

  const clickHandler = () => {
    setIsVisible(false);
    toggleModelVisibility();
    setActiveEndpoint('openAI');
    newConversation();
    if (messagesTree && messagesTree.length > 0) {
      resetPoweredBy();
    }
    if (isMobile) {
      setTimeout(() => onAfterClick(), 200);
      setNavVisible(false);
    }
  };

  return (
    <>
      <div className="hidden md:flex items-center py-6">
        <img
          src="/assets/chatbot-ask-logo-icon.svg"
          alt="Chatbot Ask"
          className="block dark:hidden"
        />
        <img
          src="/assets/chatbot-ask-logo-icon.svg"
          alt="Chatbot Ask"
          className="hidden dark:block"
        />
        <span className="ml-2 text-base font-semibold text-gray-900 dark:text-white">ChatbotAsk</span>
      </div>
      <div
        onClick={clickHandler}
        className="new-chat-btn text-sm transition-all rounded-lg p-2 mb-1 duration-200 hover:bg-gray-100 dark:hover:bg-gray-800"
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            clickHandler();
          }
        }}
        aria-label="New chat"
      >
        <span className='inline-flex pt-1'><IoMdAddCircleOutline className='mr-2' size={20} /> New Chat</span>
      </div>
      <hr className='py-2 dark:brightness-[30%]'></hr>
    </>
  );
};

export default NewChat;
