import React, { useEffect, useRef } from 'react';
import CheckMark from '../svg/CheckMark';
import { GoPencil } from 'react-icons/go';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default function RenameButton({ renaming, renameHandler, onRename, isCurrentConversation }) {
  const buttonRef = useRef(null);
  const tooltipRef = useRef(null);
  const handler = renaming ? onRename : renameHandler;

  useEffect(() => {
    if (buttonRef.current) {
      if (!tooltipRef.current) {
        tooltipRef.current = tippy(buttonRef.current, {
          content: renaming ? "Save" : "Edit",
          placement: 'top',
          delay: [500, 0],
          arrow: true,
          theme: 'tooltip-black',
          trigger: 'mouseenter focus',
          touch: false,
        });
      } else {
        tooltipRef.current.setContent(renaming ? "Save" : "Edit");
      }
    }
  }, [renaming]);

  return (
    <div className="group/rename relative">
      <button 
        ref={buttonRef}
        className={`rename-btn px-1 ${!isCurrentConversation ? 'opacity-50 hover:opacity-100' : ''}`}
        onClick={handler}
      >
        {renaming ? <CheckMark /> : <GoPencil />}
      </button>
    </div>
  );
}
