import { useState, forwardRef } from 'react';
import { useRecoilValue } from 'recoil';
import { Download } from 'lucide-react';
import { cn } from '~/utils/';

import ExportModel from './ExportModel';

import store from '~/store';
import { useLocalize } from '~/hooks';

const ExportConversation = forwardRef(() => {
  const [open, setOpen] = useState(false);
  const localize = useLocalize();

  const conversation = useRecoilValue(store.conversation) || {};

  const exportable =
    conversation?.conversationId &&
    conversation?.conversationId !== 'new' &&
    conversation?.conversationId !== 'search';

  const clickHandler = () => {
    if (exportable) {
      setOpen(true);
    }
  };

  return (
    <>
      <button
        className="export-btn p-1"
        onClick={clickHandler}
      >
        <Download size={16} />
        {/* {localize('com_nav_export_conversation')} */}
      </button>

      <ExportModel open={open} onOpenChange={setOpen} />
    </>
  );
});

export default ExportConversation;
