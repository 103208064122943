import React, { useState, useRef, useEffect } from 'react';
import HeaderDropdown from './HeaderDropdown';
import { showPricing } from '~/components/helper/global';
import { usePricingPopup, subscriptionType, setWindowValue } from '~/components/helper/store';
import { FaUser } from "react-icons/fa6";
import Settings from './HeaderSettings';
import { IoDiamondOutline } from "react-icons/io5";
import { ModelSelect } from '../Input/ModelSelect';
import store from '~/store';
import { useRecoilValue } from 'recoil';
import { useSetOptions } from '~/hooks';

export default function Header({ showThemeModal, navVisible }) {
  const conversation = useRecoilValue(store.conversation);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const pState = usePricingPopup((state) => state);
  const proState = subscriptionType((state) => state)
  const { setOption } = useSetOptions();
  const setModel = setOption('model');

  const toggleDropdown = () => {
    if (isDropdownOpen) {
      setIsDropdownVisible(false);
      setTimeout(() => setIsDropdownOpen(false), 200);
    } else {
      setIsDropdownOpen(true);
      setTimeout(() => setIsDropdownVisible(true), 10);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownVisible(false);
      setTimeout(() => setIsDropdownOpen(false), 200);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className={`absolute top-0 right-0 md:flex items-center justify-between py-2 px-4 z-10 transition-width duration-200 ${navVisible ? 'w-[calc(100vw-252px)]' : 'w-[calc(100vw-17px)]'} `}>
      {/* <div className="flex items-center">
        <img
          src="/assets/chatbot-ask-logo-icon.svg"
          alt="ChatbotAsk"
          className="block dark:hidden"
        />
        <img
          src="/assets/chatbot-ask-logo-icon.svg"
          alt="ChatbotAsk"
          className="hidden dark:block"
        />
        <span className="ml-2 text-[26px] font-bold text-gray-900 dark:text-white">ChatbotAsk</span>
      </div> */}

      <div className='text-white mr-auto'>
        <ModelSelect conversation={conversation} setOption={setOption} />
      </div>

      <div className="flex items-center relative">
        {(!proState.isPro && !proState.isProMax) && (
          <button
            className="go_pro_btn px-2 py-1.5 rounded-full hidden items-center mr-4 md:flex bg-[#177654]"
            style={{
              color: 'white',
            }}
            onClick={() => {
              setWindowValue('mxclickLocation', 'Go-Pro-Button')
              showPricing(() => {
                pState.setShowPricingPopup(true);
              });
            }}
            title="GO PRO"
            type="button"
          >
            <IoDiamondOutline className='inline mr-2 mb-0.5 ml-1' />
            <span className="mr-2 font-medium">GO PRO</span>
          </button>
        )}
        <button
          onClick={toggleDropdown}
          className="bg-[#015288] text-[white] focus:outline-none text-xl font-bold rounded-full w-[45px] h-[45px] items-center justify-center relative hidden md:flex"
        >
        {AIPRO_USER.email ? <FaUser size={20} role="img" aria-label="User"/> : 'G'}
        </button>

        {isDropdownOpen && (
          <div ref={dropdownRef}>
            <HeaderDropdown isVisible={isDropdownVisible} setIsDropdownVisible={setIsDropdownVisible} setShowSettings={setShowSettings} showThemeModal={showThemeModal} />
          </div>
        )}
      </div>
      {showSettings && <Settings open={showSettings} onOpenChange={setShowSettings} />}
    </header>
  );
}
